<template>
  <v-container>

    <v-card class="glass">
        <v-card-title>
            Actividad
        </v-card-title>
        <v-card-text>

            <v-row>
                <v-col cols="12" md="6">
     <v-select
                v-model="selectedPeriod"
                :items="periodOptions"
                label="Selecciona un periodo"
                @change="onPeriodChange"
                hide-details=""
                rounded
                filled
                class="rounded-lg"
                dense
                ></v-select>
                </v-col>
                <v-col cols="12" md="6"  v-if="selectedPeriod === 'Periodo personalizado'">
                        <v-menu
                        
               
                v-model="menu"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                max-width="290px"
                min-width="290px"
                >
                <template v-slot:activator="{ on }">
                    <v-text-field dense
                    hide-details=""
                    v-model="dateRangeText"
                    label="Selecciona un rango de fechas"
                    prepend-inner-icon="mdi-calendar"
                    readonly
                    v-on="on"
                     rounded
                filled
                class="rounded-lg"
                    ></v-text-field>
                </template>
                <v-date-picker
                    v-model="dateRange"
                    range
                    @change="onDateRangeChange"
                ></v-date-picker>
            </v-menu>
                </v-col>
            </v-row>
        

            

        </v-card-text>


         <v-fade-transition  hide-on-leave >
                  <!-- Activity List -->

                    <v-skeleton-loader  type="list-item-two-line,list-item-two-line" v-show="loading"></v-skeleton-loader>
            </v-fade-transition>



            <v-scroll-x-transition hide-on-leave>

                    <v-list v-show="!loading && workouts.length" class="glass py-0 rounded-lg overflow-hidden">

                        <template v-for="(workout, index) in workouts">
                    <v-list-item
                        :key="index"
                        two-line
                    >

                    <v-list-item-avatar>
                        <UserImage :user="{id:workout.userId}" xsmall />
                    </v-list-item-avatar>
                        <v-list-item-content>
                        <v-list-item-title style="now">{{ workout.displayName }}
                            realizó un entrenamiento!
                        </v-list-item-title>
                        <v-list-item-subtitle>{{ formatDate(workout.date) }}</v-list-item-subtitle>
                        </v-list-item-content>
                    </v-list-item>

                    <v-divider :key="index+'divid'"   v-show="index < workouts.length - 1"
                    />
                    </template>
                    </v-list>

            </v-scroll-x-transition>

                        <v-scroll-x-transition hide-on-leave>
                            

                            <v-alert class="mx-4"
                            v-show="!loading && !workouts.length"
                            type="info"
                          text
                            >
                            No hay actividad para mostrar.
                            </v-alert>
            </v-scroll-x-transition>


    </v-card>
    





   

  
  </v-container>
</template>

<script>
import moment from 'moment';
import {
  getFirestore,
  collection,
  collectionGroup,
  query,
  where,
  getDocs,
  orderBy,
  onSnapshot,
} from 'firebase/firestore';
import UserImage from '@/components/profile/UserImage.vue';

export default {
  components: {
    UserImage,
  },
  name: 'Actividad',
  data() {
    return {
      loading: true,
      selectedPeriod: 'Ultima semana',
      periodOptions: ['Ultima semana', 'Ultimo mes', 'Periodo personalizado'],
      menu: false,
      dateRange: [],
      dateRangeText: '',
      workouts: [],
      currentUserId: 'CURRENT_USER_ID', // Replace with actual current user ID
      unsubscribes: [],
      workoutsMap: {},
    };
  },
  watch: {
    selectedPeriod() {
      if (this.selectedPeriod !== 'Periodo personalizado') {
        this.fetchWorkouts();
      }
    },
    dateRange: {
      handler(newVal) {
        if (newVal.length === 2) {
          this.fetchWorkouts();
        }
      },
      deep: true,
    },
  },
  methods: {
    onPeriodChange() {
      if (this.selectedPeriod !== 'Periodo personalizado') {
        this.menu = false;
      } else {
        this.menu = true;
      }
    },
    onDateRangeChange() {
      this.menu = false;
    },
    formatDate(date) {
      let str = moment(date.toDate()).locale('es').fromNow();
      return str;
    },
    async fetchWorkouts() {
      // Clean up previous listeners
      this.unsubscribes.forEach((unsubscribe) => unsubscribe());
      this.unsubscribes = [];
      this.workoutsMap = {};
      this.workouts = [];
      this.loading = true;

      const db = getFirestore();
      let startDate, endDate;

      if (this.selectedPeriod === 'Ultima semana') {
        startDate = moment().subtract(7, 'days').startOf('day').toDate();
        endDate = new Date();
      } else if (this.selectedPeriod === 'Ultimo mes') {
        startDate = moment().subtract(1, 'months').startOf('day').toDate();
        endDate = new Date();
      } else if (
        this.selectedPeriod === 'Periodo personalizado' &&
        this.dateRange.length === 2
      ) {
        startDate = moment(this.dateRange[0]).startOf('day').toDate();
        endDate = moment(this.dateRange[1]).endOf('day').toDate();
      } else {
        startDate = null;
        endDate = null;
      }

      try {
        // Fetch all users except the current user
        const usersCollection = collection(db, 'users');
        const usersQuery = query(
          usersCollection,
          where('__name__', '!=', this.currentUserId)
        );
        const usersSnapshot = await getDocs(usersQuery);
        const users = usersSnapshot.docs.map((doc) => ({
          id: doc.id,
          displayName: doc.data().displayName,
        }));

        // Set up real-time listeners for each user's workouts
        users.forEach((user) => {
          let workoutsCollection = collection(db, `users/${user.id}/workouts`);
          let workoutsQuery;

          if (startDate && endDate) {
            workoutsQuery = query(
              workoutsCollection,
              where('date', '>=', startDate),
              where('date', '<=', endDate),
              orderBy('date', 'desc')
            );
          } else {
            workoutsQuery = query(workoutsCollection, orderBy('date', 'desc'));
          }

          const unsubscribe = onSnapshot(
            workoutsQuery,
            (snapshot) => {
              snapshot.docChanges().forEach((change) => {
                const workoutId = change.doc.id;
                if (change.type === 'added' || change.type === 'modified') {
                  // Add or update the workout in workoutsMap
                  this.$set(this.workoutsMap, workoutId, {
                    id: workoutId,
                    userId: user.id,
                    displayName: user.displayName,
                    ...change.doc.data(),
                  });
                } else if (change.type === 'removed') {
                  // Remove the workout from workoutsMap
                  this.$delete(this.workoutsMap, workoutId);
                }
              });
              // Update workouts array
              this.workouts = Object.values(this.workoutsMap);
              // Sort workouts by date (most recent first)
              this.workouts.sort(
                (a, b) => b.date.toMillis() - a.date.toMillis()
              );
              this.loading = false;
            },
            (error) => {
              console.error('Error listening to workouts:', error);
            }
          );

          this.unsubscribes.push(unsubscribe);
        });

        if (users.length === 0) {
          // If no users are found, stop loading
          this.loading = false;
        }
      } catch (error) {
        console.error('Error fetching workouts:', error);
        this.loading = false;
      }
    },
  },
  mounted() {
    this.fetchWorkouts();
  },
  beforeDestroy() {
    // Clean up listeners
    this.unsubscribes.forEach((unsubscribe) => unsubscribe());
    this.unsubscribes = [];
  },
};
</script>

<style scoped>
/* Add any custom styles here */
</style>
